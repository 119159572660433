import React from 'react';

const Header = () => {
  return (
    <div className="header">
      <div className="header-left">
        <img src={require('../assets/images/ham3.png')} className="ham_header" />
        <img src={require('../assets/images/logo.png')} className="logo_header" />
      </div>
      <div className="header-right">
        <img src={require('../assets/images/logout.png')} className="logout-img" />
        <span className="logout-text">Logout</span>
      </div>
    </div>
  )
}
export default Header