import React from "react"; 
import CardComponent from './CardComponent'
const StripCard = ({triplistDate}) => {
    if(triplistDate){
      const {totalKm = 0 , totalTime = 0, totalTripTime = 0, totalExpences = 0, totalTrips = 0, vehicleNo = 0, otherExpenses = 0} = triplistDate
      return(
          <div className="strip_card_img_wrap">
            <div className="strip_card_container">
               <CardComponent className="skyblue" content={vehicleNo}/>
               <CardComponent className="yellow" content={`Total Trips: ${totalTrips}`}/>
               <CardComponent className="blue" content={`Total KM: ${totalKm}`}/>
               <CardComponent className="pink" content={`Trip Time: ${totalTime}`}/>
               <CardComponent className="green" content={`Total Time: ${totalTripTime}`}/>
               <CardComponent className="brown" content={`Total Exp: ${totalExpences}`}/>
            </div>
            <div className="strip_card black">
              <i class="fa fa-folder-open-o"></i>
              <span className="strip-text"> Other Exp: Rs {otherExpenses} </span>
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </div>
          </div>
      )
    }else{
      return(
        <>
        </>
      )
    } 
}

export default StripCard