  import React, { useState } from "react"; 

const CardComponent = (props) => {
const {className, content} = props;    
    return(
        <div className={`strip_card ${className}`}>
            <i class="fa fa-clock-o"></i>
            <span className="strip-text"> {content} </span>
      </div>
    )
}

export default CardComponent