import React from 'react';
import Header from './components/Header'
import StripCard from './components/StripCard'
import AccordianMenu from './components/AccordianMenu'
import NavBarWithDatePicker from './components/NavBarWithDatePicker'
import './App.css';
import axios from 'axios'

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      isAccordian: true,
      startDate: new Date(1577888571659),
      endDate: new Date(1593613371659),
      triplistDate: null
    }
  }
  apiCaller(startDate, endDate){
    // console.log(startDate, endDate)
    axios.post('//amazon.watsoo.com/watsoo-amazon-api//trip-controller-web/v1/vehicle/wise/summary/36', {
      clientId: 10,
      dataRecord: {
        userRoleId: 4,
        userRoleName: "COMPANY",
        userId: 10
      },
      fromDate: startDate.getTime(),
      toDate: endDate.getTime(),
      tripId: 36
    })
    .then((response) => {
      console.log(response.data.responseCode);
      if(response.data.responseCode === 4000){
        this.setState({triplistDate: 'No Record Found'})   
      }else{
        this.setState({triplistDate: response.data.data})
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  componentDidMount(){
    const {startDate, endDate} = this.state
    this.apiCaller(startDate, endDate)
  }
  handleDateFrom = (date) => {
    const {startDate, endDate} = this.state
    this.setState({startDate: date},()=>{
      this.apiCaller(startDate, endDate)
    })
  }
  handleDateTo = (date) => {
    const {startDate, endDate} = this.state
    this.setState({endDate: date},()=>{
      this.apiCaller(startDate, endDate)
    })
  }
  
  render() {
    const { startDate, endDate, triplistDate } = this.state
    return (
      <div className="main-wrapper">
        <Header />
        <NavBarWithDatePicker endDate={endDate} startDate={startDate} handleDateFrom={(date)=>this.handleDateFrom(date)} handleDateTo={(date)=>this.handleDateTo(date)} />
        <StripCard triplistDate={triplistDate}/>
        <AccordianMenu tripDetails={triplistDate && triplistDate.tripDetails}/>
      </div>
    );
  }

}

export default App;
