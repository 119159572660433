import React, { useState } from "react"; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const NavBarWithDatePicker = (props) => {
    const { handleDateFrom, handleDateTo, endDate, startDate } = props;
    return(
        <>
         <div className="trip-summary-wrap red">
          <h5 className="trip-summary-heading">Trip Summary</h5>
          <div className="trip-date-nav-wrap red0">
            <div className="trip-summary-left">
              <span className="nav active">Dashboard</span>
              <span className="nav marleft5">/ Trip Summary</span>
            </div>
            <div className="trip-summary-right">
              <div className="date-from-wrapper">
                <span className="from-text">From</span>
                <DatePicker selected={startDate} onChange={date => handleDateFrom(date)} />
              </div>
              <div className="date-to-wrapper">
                <span className="to-text">To</span>
                <DatePicker selected={endDate} onChange={date => handleDateTo(date)} />
              </div>
              <div className="search-btn">
                <i class="fa fa-search" aria-hidden="true"></i>
              </div>
              <div className="export-btn">
                Export
              </div>
            </div>
          </div>
        </div>
        </>
    )
}
export default NavBarWithDatePicker