import React, { useState } from "react";

const AccordianMenu = ({ tripDetails }) => {
    let [isAccordian, setAccordian] = useState(false)
    const handleAccordian = (index) => {
        setAccordian(!isAccordian)
        if (isAccordian) {
            document.getElementById(`collapse${index}`).classList.add('collapse')
            document.getElementById(`glyph${index}`).classList.add('glyphicon-plus')
            document.getElementById(`glyph${index}`).classList.remove('glyphicon-minus')
        } else {
            document.getElementById(`collapse${index}`).classList.remove("collapse");
            document.getElementById(`glyph${index}`).classList.add('glyphicon-minus')
            document.getElementById(`glyph${index}`).classList.remove('glyphicon-plus')
        }
    }
    const hanldeDate = (item) => {
        let startDate = new Date(Number(item.startDay)).toDateString()
        let endDate = new Date(Number(item.endDay)).toDateString()
        return `${startDate} - ${endDate}(${new Date(item.dailyRunningTime).getHours()} hours : 00 Minutes)`
    }

    const tableBody = (tripLists) => {
        return (
            <tbody>
                {
                    tripLists.map((data, index) => {
                        const { startPointNode, endPointNode, driverName, totalKm, tripRunningTime, tripExpenses } = data
                        return (
                            <tr key={index}>
                                <th scope="row">1</th>
                                <td>{startPointNode}(5:45 AM) -> {endPointNode}(7:45 AM)</td>
                                <td>{driverName}</td>
                                {<td>Rs: {tripExpenses.reduce((total, { amount }) => { return total + amount }, 0)}
                                </td>
                                }
                                <td>{totalKm} km</td>
                                <td>0.00 km</td>
                                <td>{new Date(tripRunningTime).getHours()} hrs 00 min</td>
                                <td>10330 ->  10445</td>
                                <td>
                                    <div className="wrapper_btn">
                                        <span className="movement-report">Movement Report</span>
                                        <span className="movement-report">Stoppage Report</span>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        )
    }


    if (tripDetails && tripDetails.length) {
        return (
            <>
                <div class="container demo">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        {
                            tripDetails.map((item, index) => (
                                <div className="panel panel-default" key={index}>
                                    <div className="panel-heading" role="tab" id="headingOne">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion" aria-expanded="true" aria-controls="collapseOne">
                                                <i id={`glyph${index}`} className={`more-less glyphicon glyphicon-minus`} onClick={() => handleAccordian(index)}></i>
                                                <div className="wrap_table_main_head">
                                                    <div className="wrapper_date_heading">
                                                        <span className="table_head_text">Date: {hanldeDate(item)} </span>
                                                    </div>
                                                    <div className="total_km_expe_wrap">
                                                        <span className="text_km"> Total KM: 165 KM</span>
                                                        <span className="text_expense">Total Expenses : 0 </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </h4>
                                    </div>
                                    <div id={`collapse${index}`} className="panel-collapse" role="tabpanel" aria-labelledby="headingOne">
                                        <div className="panel-body">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Trip Start(Node) to Trip End(Node)</th>
                                                        <th scope="col">Driver Name</th>
                                                        <th scope="col">Trip Expenses</th>
                                                        <th scope="col">Trip km</th>
                                                        <th scope="col">Trip Gps km</th>
                                                        <th scope="col">Trip Time</th>
                                                        <th scope="col">Odometer Reading</th>
                                                        <th scope="col">
                                                            Action
                                                            </th>
                                                    </tr>
                                                </thead>

                                                {tableBody(item.tripLists)}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </>
        )

    } else {
        return (
            <>
             <div style={{height:'300px'}}> No Record Found</div>
            </>
        )
    }


}

export default AccordianMenu